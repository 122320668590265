.menu {
  width: 86px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: rgb(245, 245, 245);
}
