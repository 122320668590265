.list {
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1900; /* FluentUIのPopupコンポーネントのz-indexと同じ値にしています */
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.8rem 1rem -0.2rem; /* FluentUIのDropdownコンポーネントのbox-shadowと同じ値にしています */
}

.pulldown {
  position: relative;
}
