.container {
  height: 100%;
}

.info {
  background-color: #fff;
  height: 100%;
  padding-top: 64px;
}

.header {
  background-color: #fff;
  padding: 12px 16px;

  & > .label {
    display: inline-block;
    background-color: #f5f5f5;
    color: #5b5fc7;
    margin: 0;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
  }

  & > .title {
    margin: 8px 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  margin-top: 1px;
  padding: 18px 0;
  height: 100%;
  background-color: #fff;
  overflow: scroll;
}

.firstChatItem {
  margin-top: 1.2rem;
}

.leftChatItem {
  & > div {
    margin-left: 16px;
  }

  & .leftChatMessage {
    background-color: #f5f5f5;
    margin-right: 0;
    max-width: 100%;
  }
}

.rightChatItem {
  & > div {
    margin-right: 16px;
  }

  & .rightChatMessage {
    margin-left: 0;
    max-width: 100%;
  }
}
