.container {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
}

.left img {
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.right {
  margin-left: 12px;
}
