.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.image {
  width: 300px;
}

.messageHigh {
  margin-top: 24px;
}

.message {
  margin-top: 8px;
}
