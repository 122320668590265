.icon {
  color: #242424;
}

.text {
  color: #242424;
}

.button {
  border: 1px solid #242424;
  color: #242424;
  background: transparent;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.boxer {
  border: 1px solid #242424;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  margin: 0.1rem 0;
}
