.headingText {
  color: #999;
  white-space: nowrap;
  padding-right: 80px;
}

.inputAreaHeading {
  color: #999;
  padding-right: 6px;
}

.selectFilter {
  max-height: 180px;
  overflow: auto;
  width: 100%;
}

.checkbox {
  padding-left: 0 !important;
}
