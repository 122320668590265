.container {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 0 16px;
}

.left {
}

.extensionIcon {
  cursor: pointer;
}
.extensionIcon:hover {
  color: #5b5fc7;
}

.extensionIcon.selected {
  color: #5b5fc7;
}

.extensionIcon + .extensionIcon {
  margin-left: 8px;
}

.sendIcon {
  cursor: pointer;
  color: #5b5fc7;
}
