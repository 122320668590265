.container {
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

.toolbarContainer {
  max-width: 90%;
}

.toolbar {
  overflow: hidden;
}

.trash {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.richStyle {
  min-width: 10rem !important;
}

.linkPopUpTarget {
  width: 10rem;
  margin: 0 auto;
}
