.container {
}

.container * {
  margin: 0;
}

.container p {
  font-size: 14px;
}

.container h1 {
  font-size: 18px;
  margin: 4px 0;
}

.container h2 {
  font-size: 14px;
  font-weight: bold;
  margin: 4px 0;
}

.container h3 {
  font-size: 12px;
  font-weight: bold;
  margin: 4px 0;
}

.container b {
  font-weight: bold;
}

.container strike {
  text-decoration: line-through;
}

.container ul {
  margin: 14px 0;
}

.container blockquote {
  margin: 14px 40px;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px #dddad8;
  border-left-width: 4px;
}

.container hr {
  outline: 0;
  border: none;
  border-bottom: solid 1px #dddad8;
  margin: 8px 0;
}

.container :global(.codeblock) {
  border-radius: 4px;
  border: solid 2px rgb(240, 240, 240);
  border-top-color: rgb(91, 95, 199);
  padding: 8px 16px;
}

.container :global(.codeblock) :global(.title-row-left) {
  display: flex;
}

.container :global(.codeblock) :global(.title) {
  font-size: 18px;
  font-weight: bold;
}

.container :global(.codeblock) :global(.language) {
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
  color: #424242;
}

.container :global(.codeblock) pre {
  display: block;
  font-family: 'Cascadia Mono', Consolas, ui-monospace, Menlo, Monaco, monospace;
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.4;
  counter-reset: line-number;
  border: solid 1px rgb(209, 209, 209);
  border-radius: 4px;
  padding: 4px 16px;
}
.container :global(.codeblock) pre > span {
  position: relative;
  counter-increment: line-number;
  list-style: none;
  padding-left: 32px;
}
.container :global(.codeblock) pre > span::before {
  position: absolute;
  display: block;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 14px;
  content: counter(line-number);
  color: rgb(36, 36, 36);
  opacity: 0.5;
}

.container table {
  border: solid 1px black;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 16px 0;
}

.container th,
.container td {
  border: solid 1px black;
}
