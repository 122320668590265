.td {
  width: 100%;
  border-top: solid 1px rgb(237 235 233);
  display: flex;
  align-items: center;
}

.table tr:last-child td {
  border-bottom: solid 1px rgb(237 235 233);
}

.newButton {
  padding: 8px 10px;
  border: 0;
  width: 360px;
}

.iconcontainer {
  padding: 0 10px;
  width: 30px;
  cursor: move;
}

.icon {
  color: #575757;
}

.disabledIcon {
  color: #d6d6d6;
}
