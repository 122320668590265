.imageContainer {
  position: relative;
}

.text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  text-align: center;
  align-items: center;
  font-size: 1.3rem;
}
