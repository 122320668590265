.dialog {
  min-width: 400px;
  max-width: 400px;
  min-height: 300px;
  max-height: 640px;
}

@media screen and (max-width: 425px) {
  .dialog {
    min-width: auto;
    width: 100%;
  }
}

.container {
  margin: 20px 0;
}
.fixedTypeLabel {
  padding-left: 12px;
}
