.container {
  display: inline-flex;
  padding: 4px 12px;
  align-items: center;
  border: 1px solid #e1dfdd;
  border-radius: 9999px;
  background-color: white;
}

.text {
  flex: 1;
  color: #5b5fc7;
}

.closeIcon {
  margin-left: 4px;
  cursor: pointer;
}
