.container {
  position: relative;
  display: flex;
  padding: 8px 16px;
  background-color: #fafafa;
}

.container:hover {
  background-color: #f7f7f7;
}

.containerBorder::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #5b5fc7;
}

.highlightBorder::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: rgb(233, 117, 72);
}

.avatarBox {
  min-width: 32px;
  padding-top: 4px;
}

.infoRow {
  display: flex;
  align-items: flex-start;
}

.messageBox {
  flex: 1;
  margin-left: 16px;
}

.infoRowTime {
  margin-left: 8px;
  color: #424242;
}

.messageBody {
}
