.container {
  max-height: 140px;
  overflow-y: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.td {
  box-sizing: content-box;
  border-top: solid 1px rgb(237, 235, 233);
  border-bottom: solid 1px rgb(237, 235, 233);
  white-space: pre-wrap;
  font-weight: normal;
}
.td input {
  padding: 8px 10px;
  border: 0;
  width: 240px;
}
.td input:focus {
  outline: 0;
}
.iconcontainer {
  padding: 0 10px;
  cursor: move;
}
.icon {
  color: #999;
}
.input:hover {
  cursor: text;
}
.tr:last-child td {
  padding-left: 35px;
}
.deleteButton {
  float: right;
}
