.container {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.headerRow {
  padding: 0 20px;
}

.body {
  margin: 16px auto 0;
  max-width: 578px;
}
