.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  height: 22px;
}

.container:global(.draft) {
  background-color: #c9c9c9;
  color: #fff;
}

.container:global(.scheduled),
.container:global(.pending) {
  background-color: #fff;
  border: solid 1px #5b5fc7;
  color: #5b5fc7;
}

.container:global(.sent) {
  background-color: #5b5fc7;
  color: #fff;
}
