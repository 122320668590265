.container {
  padding: 16px;
  font-size: 14px;
  background-color: #ffffff;
}

.faqCreateButton {
  width: 182px;
  height: 32px;
}

.skipButton {
  width: 154px;
  height: 32px;
}

.contentFooter {
  gap: 20px;
  line-height: 1;
  align-items: center;
  padding-right: 8px;
}

.faqFlexBox {
  gap: 8px;
  line-height: 1.5;
}

.faqTextArea {
  max-width: 648px;
  line-height: 1;
}

.faqPropertyInput {
  max-width: 256px;
}

.faqButtonFlexBox {
  gap: 8px;
}

.faqItemContentFlexBox {
  gap: 12px;
}

.effectMessage {
  min-width: 284px;
  color: #5b5fc7;
}
