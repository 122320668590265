.editorArea {
  overflow-y: auto;
  max-height: 300px;
}

a {
  text-decoration: none;
  color: rgb(91, 95, 199); /* Teams本家UIと同色 */
}

a:hover {
  text-decoration: underline;
}

.Indent1 {
  margin-left: 40px;
}

.Indent2 {
  margin-left: 80px;
}

.Indent3 {
  margin-left: 120px;
}

.Indent4 {
  margin-left: 160px;
}

.Indent5 {
  margin-left: 200px;
}

.Indent6 {
  margin-left: 240px;
}

.atomic {
  margin: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.blockquote {
  border-left: 0.4rem solid #d1d1d1;
  font-size: 1.4rem;
  background-color: #f5f5f5;
  color: #424242;
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 0rem 1.6rem 0rem 1.1rem;
}

.blockquoteFirst {
  padding-top: 0.7rem;
  margin-top: 0.35rem;
}

.blockquoteLast {
  padding-bottom: 0.7rem;
  margin-bottom: 0.35rem;
}

.codeBlock {
  display: block;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 0.2rem;
  background: #d1d1d1;
  font-size: 1.4rem;
  background-color: #f5f5f5;
  color: #424242;
  border: none;
  line-height: 2rem;
  padding: 0.7rem 1.6rem 0.6rem;
  margin: 0.7rem 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.unOrderedList {
  list-style: disc !important;
}

.unOrderedList1 {
  list-style-type: circle;
}

.unOrderedList2 {
  list-style-type: square;
}

.orderedList1::before {
  content: counter(ol1) '. ' !important;
  counter-increment: ol1 !important;
}

.orderedList2::before {
  content: counter(ol2) '. ' !important;
  counter-increment: ol2 !important;
}

.orderedList3::before {
  content: counter(ol3) '. ' !important;
  counter-increment: ol3 !important;
}

.orderedList4::before {
  content: counter(ol4) '. ' !important;
  counter-increment: ol4 !important;
}

.headerOne {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.33333;
  margin: 0.3rem;
}

.headerTwo {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4286;
  margin: 0.3rem;
}

.headerThree {
  font-size: 1.2rem;
  line-height: 1.25;
  margin: 0.3rem;
}
