.tableSelector {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tableRow {
  display: flex;
  gap: 4px;
}

.tableCol {
  width: 18px;
  height: 18px;
  content: '';
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  cursor: pointer;
}

.tableCol.hover {
  background-color: rgba(91, 95, 199, 0.8);
}
