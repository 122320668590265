.container {
}

.inputCardBox {
  border-radius: 4px;
  background-color: white;
}

.contentEditable {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.4;
  outline: none;
}
.contentEditable.expanded {
  min-height: 92px;
}
.contentEditable p {
  margin: 0;
}
.contentEditable h1 {
  margin: 4px 0;
  font-size: 18px;
  font-weight: normal;
}
.contentEditable h2 {
  margin: 4px 0;
  font-size: 14px;
  font-weight: bold;
}
.contentEditable h3 {
  margin: 4px 0;
  font-size: 12px;
  font-weight: bold;
}
.contentEditable .font-bold {
  font-weight: bold;
}
.contentEditable .font-italic {
  font-style: italic;
}
.contentEditable :global(.font-underline) {
  text-decoration: underline;
}
.contentEditable :global(.font-strikethrough) {
  text-decoration: line-through;
}
.contentEditable :global(.font-underline-strikethrough) {
  text-decoration: underline line-through;
}
.contentEditable :global(.nested-list-item) {
  list-style: none;
}
.contentEditable :global(.quote) {
  border-radius: 4px;
  padding: 8px 16px;
  border: solid 1px rgb(209, 209, 209);
  border-left-width: 4px;
  font-style: italic;
}
.contentEditable :global(.important) {
  position: relative;
  padding-top: 24px;
}
.contentEditable :global(.important)::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  content: '重要!';
  color: rgb(196, 49, 75);
}
.contentEditable hr {
  outline: none;
  border: none;
  border-bottom: solid 1px rgb(209, 209, 209);
  margin: 8px 0;
  height: 0;
}
.contentEditable :global(.codeblock) {
  border-radius: 4px;
  border: solid 2px rgb(240, 240, 240);
  border-top-color: rgb(91, 95, 199);
  padding: 8px 16px;
}
.contentEditable :global(.codeblock) :global(.title) {
  font-size: 18px;
  font-weight: bold;
}
.contentEditable :global(.codeblock) :global(.language) {
  font-weight: normal;
  font-size: 14px;
  margin-left: 16px;
  color: #424242;
}
.contentEditable :global(.codeblock) pre {
  display: block;
  font-family: 'Cascadia Mono', Consolas, ui-monospace, Menlo, Monaco, monospace;
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.4;
  counter-reset: line-number;
  border: solid 1px rgb(209, 209, 209);
  border-radius: 4px;
  padding: 4px 16px;
}
.contentEditable :global(.codeblock) pre > span {
  position: relative;
  counter-increment: line-number;
  list-style: none;
  padding-left: 32px;
}
.contentEditable :global(.codeblock) pre > span::before {
  position: absolute;
  display: block;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 14px;
  content: counter(line-number);
  color: rgb(36, 36, 36);
  opacity: 0.5;
}
.contentEditable :global(.codeblock) > :global(.title-row) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentEditable :global(.codeblock) :global(.title-row-left) {
  display: flex;
  align-items: center;
}
.contentEditable table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}
.contentEditable table p {
  display: inline;
  font-weight: normal;
}
.contentEditable table,
.contentEditable td,
.contentEditable th {
  border: solid 1px black;
  border-collapse: collapse;
}

.richTextContainer {
  position: relative;
  /* 下部はborder2px分引いておく */
  border-bottom: 2px solid transparent;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.richTextContainer.expanded {
  min-height: 92px;
}
.richTextContainer.focused {
  border-color: #5b5fc7;
}

.placeholder {
  color: #424242;
  position: absolute;
  top: 8px;
  left: 16px;
  pointer-events: none;
  user-select: none;
}

.extensionToolbar {
  margin-top: 4px;
}

.richTextToolbar {
  padding-top: 8px;
}

.attachments {
  padding: 0px 16px 8px;
}
