.header {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.tooltip {
  background: #fff;
  border-radius: 0.2rem;
  cursor: pointer;
}

.tooltip :hover {
  background: #f3f2f1;
}

.tooltipItem {
  padding: 8px;
}

.image {
  cursor: pointer;
}

/*imitate css from Microsoft teams app start*/
.richText {
  font-size: 14px;
}

.richText ul {
  list-style-type: disc;
}

.richText h1 {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.33333;
  display: block;
  margin: 0.3rem 0;
}

.richText h2 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4286;
  display: block;
  margin: 0.3rem 0;
}

.richText h3 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.25;
  display: block;
  margin: 0.3rem 0;
}

.richText pre {
  background-color: #f5f5f5;
  padding: 0.7rem 1.6rem 0.6rem;
  margin: 0.7rem 0;
  border: none;
  border-radius: 0.2rem;
}

.richText table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.richText blockquote {
  margin: 0.7rem 0;
  padding: 0.7rem 1.6rem 0.7rem 1.1rem;
  border-left: 0.4rem solid #d1d1d1;
  background-color: #f5f5f5;
  color: #424242;
}

/* For hierarchy table start */
.richText table table {
  border-collapse: collapse;
  border: solid 1px #bdbdbd;
  background-color: #fff;
}

.richText table table td {
  border: solid 1px #bdbdbd;
}
/* For hierarchy table end */

/*imitate css from Microsoft teams app end*/

.activityLogCheckbox {
  cursor: default !important;
  margin: 0 1rem !important;
  padding: 0 !important;
  display: inline-block !important;
}

.activityLogCheckboxOn:hover > div {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: %235b5fc7; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'%3E%3Cg%3E%3Cpath fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /%3E%3C/g%3E%3C/svg%3E") !important;
}
