.container {
  background-color: #f4f4f4;
}

.ticketItem {
  margin-top: 16px;
}

.ticketItem:last-child {
  padding-bottom: 16px;
}

.dividerItem {
  /* ticketItem と隣合わせた時の　`margin-top` を半分打ち消す */
  margin-bottom: -8px;
}

.dividerItem:not(:first-child) {
  margin-top: 8px;
}
