.autoAssignConfigDialogHeader {
  margin: 20px 0 10px 0;
  font: bold;
}

.autoAssignConfigDialogContent {
  margin: 20px 0;
}

.dropdown {
  width: 100%;
}

.dialog {
  min-width: 640px;
}
