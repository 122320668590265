.body {
  width: 668px;
}

.formCard {
  margin-top: 24px;
  background-color: #fff;
}

.formCardInner {
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
}

.formBody {
  min-height: 300px;
  width: 100%;
}

.formBodyError {
  font-size: 12px;
  margin-top: 4px;
  color: #d13438;
}

.formAreaOuter {
  margin-top: 8px;
  position: relative;
}

.formFileList {
  width: 100%;
  margin-top: 12px;
}

.formBodyAttachments {
  margin-top: 8px;
}

.formReceiverTitle {
  margin-top: 16px;
}

.formReceiver {
  margin-top: 4px;
}

.formReceiverMembers {
  margin-top: 4px;
}

.formCardFooter {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;
  margin-top: 24px;
  border-top: solid 1px #ebebeb;
}

.submitButton {
  margin-left: 8px;
}
