.pill {
  background-color: white !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  height: 28px !important;
}

.resetButton {
  height: 28px !important;
  margin-top: 6px !important;
  color: grey !important;
}

.pillTitle {
  min-width: 60px;
}

.skelton {
  width: 120px !important;
}

.pillSkelton {
  background-color: white !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  height: 28px !important;
  width: 120px !important;
}

.skeltonShape {
  display: inline !important;
  background-color: transparent !important;
}

.dropdown {
  width: 100%;
}
