.list {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.fileWrap a {
  display: flex;
  padding: 8px;
  max-width: 370px;
  align-items: center;
  background-color: #f0f0f0;
  color: #242523;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-decoration: none;
}
.fileWrap + .fileWrap {
  margin-left: 8px;
}

.icon {
  margin-left: 4px;
}

.fileName {
  margin-left: 4px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
