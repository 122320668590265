.container {
  cursor: pointer;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrow {
  margin-right: 0.2rem;
}

.text {
  color: #616161;

  &:hover {
    border-bottom-color: #bdbdbd;
    box-shadow: none;
    color: #242424;
    outline: 0;
  }
}
