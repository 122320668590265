.checkbox {
  padding-left: 0 !important;
}

.filter {
  width: 100%;
}

.dateFilterSubject {
  width: 60px;
}

.numberFilterSubject {
  width: 60px;
}

.createdAtFilterSubject {
  height: 30px;
  line-height: 30px;
}

.timeSymbol {
  height: 30px;
  line-height: 30px;
  padding: 0 12px 0 12px;
}

.tabConfigContainer {
  padding: 10px 0 10px 0;
}

.tabConfigFilterContainer {
  width: 100%;
  padding: 10px 24px 0 0;
}

.tabConfigFilterLabel {
  min-width: 120px;
  height: 30px;
  line-height: 30px;
}

.tabConfigFilterCustomFieldLabel {
  font-size: 10px;
  line-height: 10px;
}

.configSubTitle {
  padding: 12px 0 6px 0;
}

.configTitle {
  padding: 30px 0 0 0;
}

.padding5 {
  padding: 5px;
}

.filterRowRight {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media screen and (max-width: 425px) {
  .filterTimeDropdown {
    width: 64px;
  }
}

.customFilter {
  width: 100%;
  max-width: 300px;
}

.defaultTabAlert {
  background: none;
}

.defaultTabAlert > div {
  width: 80%;
}
