.richText p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.richText table {
  border-collapse: collapse;
  color: rgb(36, 36, 36);
  border-color: rgb(209, 209, 209);
  border-width: 0.1rem;
  border-style: solid;
}

.richText td {
  border-color: rgb(209, 209, 209);
  padding-left: 0.5rem;
  padding-bottom: 0px;
  padding-right: 0.5rem;
  padding-top: 0px;
  word-break: normal;
  border-width: 0.1rem;
  border-style: solid;
}

.richText :global(.codeblock) {
  border-radius: 4px;
  border: solid 2px rgb(240, 240, 240);
  border-top-color: rgb(91, 95, 199);
  padding: 8px 16px;
}
.richText :global(.codeblock) p {
  font-size: 14px;
}
.richText :global(.codeblock) pre {
  display: block;
  font-family: 'Cascadia Mono', Consolas, ui-monospace, Menlo, Monaco, monospace;
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.4;
  counter-reset: line-number;
  border: solid 1px rgb(209, 209, 209);
  border-radius: 4px;
  padding: 4px 16px;
}
.richText :global(.codeblock) pre > span {
  position: relative;
  counter-increment: line-number;
  list-style: none;
  padding-left: 32px;
}
.richText :global(.codeblock) pre > span::before {
  position: absolute;
  display: block;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 14px;
  content: counter(line-number);
  color: rgb(36, 36, 36);
  opacity: 0.5;
}
