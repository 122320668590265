.activityLog {
  background: transparent;
  font-size: 1.3rem;
}

.activityLogInner {
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.activityLogLabel {
  text-align: center;
  margin: 0 10px;
  max-width: 80%;
}

.activityLogLabel .head {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activityLog hr {
  width: 100%;
  border-top: none;
  border-bottom: 1px solid #aaa;
}

.activityLogDecoration {
  width: 20%;
}

.activityLogEmphasisText {
  font-weight: bold;
}
