.container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-left: 1px solid #d1d1d1;
  width: 360px;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.closeIcon {
  cursor: pointer;
}

.body {
  flex: 1;
  padding: 16px 24px;
  border-top: 1px solid #d1d1d1;
}

.item {
  margin-bottom: 12px;
}

.formRow + .formRow {
  margin-top: 12px;
}

.datepicker {
  display: flex;
  width: 100%;
  margin-top: 8px;
}

.datepicker > div {
  flex: 1;
  background-color: white;
}

.datepicker input {
  width: 100%;
  background-color: white;
}

.footer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d1d1d1;
}

.footer .clearButton {
  min-width: initial;
  padding: 0;
}
.footer .cancelButton {
  min-width: initial;
  width: 96px;
  padding: 0;
}
.footer .applyButton {
  margin-left: 8px;
  min-width: initial;
  padding: 0;
  width: 80px;
}
