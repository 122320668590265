:root {
  --placeholder-color: #c9c9c9;
  --disabled-color: #000000;
  --disabled-background-color: #dddad8;
}

.inputField::placeholder {
  color: var(--placeholder-color) !important;
}
.inputField:disabled {
  color: var(--disabled-color) !important;
  background-color: var(--disabled-background-color) !important;
}

.inputField input::placeholder {
  color: var(--placeholder-color) !important;
}
.inputField input:disabled {
  color: var(--disabled-color) !important;
  background-color: var(--disabled-background-color) !important;
}

.disabledLabelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.disabledLabelItem {
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: var(--disabled-background-color);
  box-sizing: border-box;
}
