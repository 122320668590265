.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(37 36 36 / 75%);
}

.container {
  width: 360px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 18px 0 rgb(33 33 33 / 12%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 16px;
  border-bottom: solid 1px #d1d9dd;
}

.body {
  height: 50vh;
  overflow: auto;
}

.bodyInner {
  position: relative;
  width: 100%;
}

.listItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.listItem:not(:last-child) {
  border-bottom: solid 1px #d1d9dd;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 68px;
  padding: 0 16px;
  border-top: solid 1px #d1d9dd;
}
