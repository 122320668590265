.panel {
  width: 40vw;
  height: 100%;
  background-color: #fafafa;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
}

@media screen and (width <= 768px) {
  .panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    z-index: 1000;
  }

  .panelClosed {
    transform: translateX(100%);
    width: 100vw;
  }
}

.panelClosed {
  width: 0;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #dddad8;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.content {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.panelContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
  visibility: visible;
  transition: visibility 0 linear;
  box-shadow: -1.6px 0 10px rgb(0 0 0 / 15%);
}

.panelContainerHidden {
  visibility: hidden;
  transition: visibility 0 linear 0.3s;
}

.documentContainer {
  margin-bottom: 24px;
}

.documentTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333;
}

.documentContent {
  font-size: 14px;
  color: rgb(36 36 36);
  word-break: break-all;
  white-space: pre-wrap;
}

.divider {
  margin: 24px 0;
  border: none;
  border-top: 1px solid #e0e0e0;
}

.fileName {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid #dddad8;
}
