.container {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 15px;
  background: #6268ac;
  border-radius: 3px;
  color: white;
  box-shadow: 1px 1px 8px 1px #969696;
  -webkit-box-shadow: 1px 1px 8px 1px #969696;
  cursor: pointer;
  overflow: hidden;
}

.content {
  overflow: hidden;
}

.title {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.message {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
