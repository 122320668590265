.container {
  height: 100vh;
  grid-template-rows: auto 36px 1fr;
}

.error {
  width: 100%;
}

.messageAlert {
  text-align: center;
}

.header {
  padding: 10px 10px 0 10px;
  grid-row: 2;
  grid-column: 1;
}

.headerFlex {
  margin: 0 auto;
  max-width: 106rem; /* Microsoft Teams Appに合わせたものです。*/
}

.headerToolsFlex {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
}

.headerToolsButton {
  padding: 0 0.2rem !important;
}

.headerToolsButton > div:nth-child(1) {
  margin-right: 0.5rem;
}

.headerToolsButtonText {
  font-weight: 500 !important;
}

.side {
  grid-row: 2 / span 2;
  grid-column: 2;
  overflow: auto;
  padding: 12px 12px;
}
.main {
  padding: 10px;
  grid-row: 3;
  grid-column: 1;
  overflow-y: auto;
}

.copyParent {
  position: relative;
  overflow: hidden;
}

.copyLabel {
  opacity: 0;
  position: absolute;
  cursor: default;
  left: 0;
  z-index: 1;
}

.ticketChannelLabel {
  padding: 0;
  font-weight: bold;
}

.ticketChannelDropdown {
  width: 100%;
}

.ticketCustomFieldTextArea {
  height: 200px !important;
}

.ticketCustomFieldSelectDropdown {
  width: 100%;
}

.ticketCustomFieldNumberInput {
  width: 100%;
  padding-right: 0;
}

.ticketCustomFieldDatePicker {
  min-width: 225px;
}

.backgroundColorGrey {
  background-color: #f5f5f5;
}
