.container {
  position: relative;

  /* リストのVirtualScrollとかぶるため */
  z-index: 1;
  background-color: #fafafa;
  min-height: 100px;
  box-shadow: 0 -1.6px 5px rgb(0 0 0 / 11%);
}

.inner {
  padding: 32px 84px 8px;
}
