.container {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  padding: 0 20px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
