.container {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-left: solid 2px rgb(240 240 240);
  border-bottom: solid 2px rgb(240 240 240);
  border-right: solid 2px rgb(240 240 240);
}

.container::before {
  position: absolute;
  top: 0;
  left: -2px;
  content: '';
  height: 3px;
  width: calc(100% + 4px);
  background-color: rgb(28 75 122);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.row + .row {
  margin-top: 16px;
  border-top: solid 1px #eeeeee;
  padding-top: 16px;
}

.item {
  color: rgb(91, 95, 199);
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 16px;
}
.item:hover {
  background-color: rgb(232, 235, 250);
}

.item + .item {
  margin-top: 8px;
}
