.requesterCell,
.faqCreatorCell {
  padding: 10px 10px 5px 0px;
}
.requesterCellText,
.faqCreatorCellText {
  padding-left: 20px;
}
.checkbox {
  height: 1.4rem;
  width: 1.4rem;
  padding-bottom: 25px !important;
}
.ticketListHeaderBoxContainer {
  margin: 0 20px;
  padding-top: 10px;
}
.exportIcon {
  margin-right: 4px;
}
.openCustomFieldIcon {
  margin: 0 4px 0 2px;
}
.ticketDisplayInfoContainer {
  padding-right: 8px;
}
.pagination {
  padding: 10px 20px 0 0;
}
.ColumnOptionIcon {
  display: inline;
  vertical-align: top;
  line-height: 0;
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  table-layout: fixed;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thead {
  color: #999;
  white-space: nowrap;
}
.th {
  width: 190px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: solid 1px rgb(241, 233, 225);
}
.td {
  width: 190px;
  padding-left: 7px;
  box-sizing: content-box;
  border-bottom: solid 1px rgb(241, 233, 225);
  font-weight: normal;
  background-color: #fff;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.thKeyId {
  width: 60px;
}

.tdKeyId {
  width: 60px;
}

.thRequester {
  width: 150px;
}

.tdRequester {
  width: 150px;
}

.thSubject {
  width: 300px;
}

.tdSubject {
  width: 300px;
  padding-right: 7px;
  white-space: normal;
}

.tdSubject .manualIcon {
  width: fit-content;
}

.tdSubject .title {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  white-space: normal;
  overflow-wrap: break-word;
}

.thStatus {
  width: 120px;
}

.tdStatus {
  width: 120px;
}

.thDate {
  width: 150px;
}

.tdDate {
  width: 150px;
}

.th:last-child,
.td:last-child {
  padding-right: 20px;
}
/* 横スクロール時にID、依頼人、内容のtdに既に白色がなっているのでホバー時は明示的にtdを指定して色を上書きする */
/* この指定がない場合はホバー時にデフォルトフィールドの色の変化が生じない */
.tr:hover td {
  background-color: rgb(237, 235, 233);
}
.tr:first-child {
  border-top: solid 0.2rem rgb(241, 233, 225);
}

.csvExportDialogHeader {
  margin: 20px 0 10px 0;
}

.csvExportDialogContent {
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: flex-end;
}

@media screen and (max-width: 425px) {
  .csvExportDialogContent {
    flex-direction: column;
    align-items: flex-start;
  }
}

.csvExportTable {
  max-height: 216px;
  overflow: auto;
}

.columnOptionIcon {
  width: 16px !important;
  height: 16px !important;
  min-width: 0 !important;
  margin-left: 4px;
  border-style: none;
}

.backgroundColorGrey {
  background-color: #f5f5f5;
}

.paginationFlexContainer {
  height: 2rem;
}

.csvExportDialogOuter {
  min-width: 640px;
}

@media screen and (max-width: 425px) {
  .csvExportDialogOuter {
    min-width: auto;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .csvExportTableWrap {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .csvExportDialogFormItem:not(:first-child) {
    margin-top: 8px;
  }
}

.manualTicketAddButton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.recommendedFaqCount {
  width: 16px;
  height: 16px;
  background: #cc4a31;
  border-radius: 16px;
  color: #ffffff;
  display: inline-block;
  line-height: 1.4;
  font-size: 10px;
  position: relative;
  top: -2px;
  margin-left: 12px;
}

.recommendedFaqButton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.recommendedFaqText {
  line-height: 1;
  font-size: 14px;
}
