.body {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 16px 0 0 20px;
}

.leftContent {
  flex: 1;
  max-width: 680px;
}

.rightContent {
  flex: 1;
  max-width: 328px;
  height: 568px;
}

.title {
  font-size: 14px;
  line-height: 24px;
}

.lastUpdate {
  font-size: 12px;
  line-height: 24px;
  color: #828282;
}

.unconfirmed {
  font-size: 14px;
  line-height: 24px;
  text-align: end;
}

.unconfirmedCount {
  color: rgba(196, 26, 26, 1);
}

.noRecommendedFaqListContainer {
  gap: 40px;
  padding-top: 40px;
}

.noRecommendedFaqListBodyText {
  align-self: center;
  text-align: center;
  width: 518px;
  height: 27px;
  font-size: 18px;
}

.noRecommendedFaqListImage {
  width: 338px;
  align-self: center;
}

.scheduled {
  align-self: center;
  font-size: 16px;
}
