.container {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.header {
  padding: 8px 20px;
}

.body {
  margin-top: 16px;
  padding: 0 20px;
}

.bodyTitleRow {
  display: flex;
  align-items: flex-end;
}

.bodyTitleRowBackText {
  margin-left: 8px;
  font-size: 12px;
}

.bodyTitleRowBackText a {
  color: #5b5fc7;
}

.bodyContentRow {
  display: flex;
  margin-top: 16px;
}

.bodyContentLeft {
  flex: 1;
}

.bodyContentRight {
  margin-left: 20px;
  width: 324px;
}
