.container {
  height: 100%;
  margin: 0 auto;
  max-width: 106rem; /* Microsoft Teams Appに合わせたものです。*/
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  padding-bottom: 20px;
}

.bottom {
  padding: 0 67px 0 51px;
}

.inputWrapper {
  overflow: hidden;
  border-radius: 3px;
  border: 0.1rem rgb(224, 224, 224) solid;
}

.fileDropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: solid;
  border-width: 2px;
  z-index: 9999;
  opacity: 0.8;
  background: #fff;
  border-color: #5b5fc7;
}

.fileDropZone span {
  color: #5b5fc7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.fileDropZoneHidden {
  display: none;
}

.inputInner {
  padding: 10px 15px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.toggleAllAutoDisplayButton {
  cursor: pointer;
}

.toggleAllAutoDisplayButtonArrow {
  margin-right: 0.2rem;
}

.toggleAllAutoDisplayButtonText {
  color: #616161;
}

.toggleAllAutoDisplayButtonText:hover {
  border-bottom-color: #bdbdbd;
  box-shadow: none;
  color: #242424;
  outline: 0;
}
