.container {
  width: 300px;
}

.headingText {
  color: #999;
  white-space: nowrap;
  padding-right: 80px;
}

.pill {
  font-weight: bold;
  white-space: nowrap;
  height: 28px !important;
}

.dropdown {
  width: 280px;
}

.dropdownWide {
  width: 560px;
}

.createdAtTitle {
  min-width: 60px;
}

.createdAtDatePicker {
  width: 120px;
}

.requesterListItem {
  height: 24px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  line-height: 24px !important;
}
