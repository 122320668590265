.container {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

.header {
  width: 100%;
  height: 50px;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
  position: fixed;
}

.closeButton {
  width: 100px;
  margin: 0 12px;
}

.tool {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.toolZoom {
  color: white;
  cursor: pointer;
  margin: 0 12px;
}

.toolDownload {
  color: white;
  cursor: pointer;
  margin: 0 12px;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.image {
  background: white;
}
