.container {
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 0 20px;
  background-color: #f5f5f5;
  border-bottom: solid 1px #e0e0e0;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerLeft a {
  text-decoration: none;
  color: inherit;
}
