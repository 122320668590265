.container {
  min-width: 4rem;
}

.list li {
  padding-left: 2rem;
  padding-right: 2rem;
  min-height: 2.8rem;
}

.title {
  font-size: 1.2rem;
  vertical-align: middle;
}
