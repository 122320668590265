.container {
  background-color: #fff;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px 0 12px 24px;
}

.table td {
  font-size: 14px;
  color: #616161;
}

.table th:last-child,
.table td:last-child {
  padding-right: 24px;
}

.table tbody tr {
  border-bottom: solid 1px #ebebeb;
  cursor: pointer;
}

.tableHeadText {
  color: #999;
  font-size: 14px;
}
