.container {
  height: 100%;
  margin: 0 auto;
  max-width: 106rem; /* Microsoft Teams Appに合わせたものです。*/
}

.textAreaContainer {
  width: 100%;
  height: auto;
  min-height: 30%;
  margin-top: 0.6rem;
}

.chatInputWrapper {
  width: 100%;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 3px;
  border: 0.1rem rgb(224, 224, 224) solid;
}

.chatInputInner {
  border-bottom-color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.textAreaContainerTitle {
  font-weight: 600;
}

.activityLogContainerTitle {
  font-weight: 600;
  margin-top: 1.4rem;
  margin-bottom: 0.6rem;
}

.activityLogItemTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activityLogBody {
  margin-left: 4.2rem;
}

.activityLogEmphasisText {
  font-weight: bold;
}
