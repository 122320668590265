.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list > li {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
  height: 28px;
  cursor: pointer;
}
.list > li:hover {
  background-color: rgb(245, 245, 245);
}
