.table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.thead {
  color: #999;
}
.th,
.td {
  padding: 7px 10px;
  box-sizing: content-box;
  border-bottom: solid 1px rgb(237, 235, 233);
  white-space: nowrap;
  font-weight: normal;
}
.th:first-child,
.td:first-child {
  padding-left: 20px;
  width: 1rem;
  cursor: move;
}
.th:nth-child(2),
.td:nth-child(2) {
  width: 30rem;
}
.th:nth-child(3),
.td:nth-child(3) {
  width: 10rem;
}
.th:last-child,
.td:last-child {
  padding-right: 20px;
}
.tr:hover {
  background-color: rgb(237, 235, 233);
  cursor: pointer;
}
.tr:active {
  background-color: #fff;
}
.icon {
  color: #999;
}
.container {
  margin: 10px;
}

@media screen and (max-width: 425px) {
  .tableWrap {
    overflow-x: auto;
  }
}
