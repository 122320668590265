.button {
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  border-color: rgba(37, 36, 36, 0.2);
  border-radius: 4px;
  border-width: 0.1rem;
}

.button0 {
  background-color: rgb(223, 146, 153);
}

.button1 {
  background-color: rgb(244, 165, 147);
}

.button2 {
  background-color: rgb(253, 212, 114);
}

.button3 {
  background-color: rgb(229, 241, 143);
}

.button4 {
  background-color: rgb(130, 205, 168);
}

.button5 {
  background-color: rgb(157, 217, 219);
}

.button6 {
  background-color: rgb(199, 212, 232);
}

.button7 {
  background-color: rgb(235, 211, 225);
}

.flex {
  justify-content: space-between;
  margin-top: 1rem;
}
