.container {
  width: 100%;
  max-width: 680px;
  background-color: #ffffff;
  margin-top: 2px;
  margin-bottom: 2px;
}

.accordionHeader {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px 12px;
  background-color: #ffffff;
  cursor: pointer;
  justify-content: space-between;
}

.leftAccordionHeader {
  width: calc(100% - 32px);
  display: -webkit-flex;
  display: flex;
  gap: 12px;
  align-items: center;
}

.label {
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  min-width: 76px;
  left: 12px;
  top: 25%;
  bottom: 25%;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
}

.high {
  color: #c41a1a;
  background: #ffeded;
}

.medium {
  color: #1a3fc4;
  background-color: #edf2ff;
}

.low {
  color: #52c41a;
  background-color: #f6ffed;
}

.title {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordionToggleOpen {
  transition: 0.5s;
}

.accordionToggleClose {
  transform: rotate(180deg);
  transition: 0.5s;
}
