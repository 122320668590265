.modal {
  height: 560px;
  overflow-x: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tr,
.td {
  width: 100%;
  display: block;
}

.th {
  width: 100%;
  color: #6264a7;
}

.tr {
  margin-top: 4px;
  border-bottom: solid 1px rgb(237 235 233);
}

.tr:first-child {
  border-top: solid 1px rgb(237 235 233);
}

.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
