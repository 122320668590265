.container {
  position: relative;
  width: 100%;
  padding: 12px 16px;
  opacity: 0.8;
  background-color: #eff6fb;
  border: solid 1px #92cdf5;
  border-radius: 4px;

  & .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 16px;

    &:hover {
      cursor: pointer;
    }
  }
}

.info {
  display: flex;
  height: 24px;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #1c8fdb;
}
