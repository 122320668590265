.container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
}

.publisherTitle {
  color: #8a8a8a;
}

.publisherRow {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.publisherImage {
  width: 40px;
  height: 40px;
  border-radius: 9999px;
}

.publisherName {
  margin-left: 8px;
}

.previewName {
  margin-top: 16px;
  color: #8a8a8a;
}

.previewTitleRow {
  display: flex;
  align-items: center;
}

.previewPublishedDate {
  margin-left: 16px;
  color: #616161;
}

.previewCard {
  padding: 12px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  box-shadow: 2px 2px 2px 0 rgb(104 104 104 / 2%);
}

.previewBody {
  margin-top: 8px;
  color: #242424;
}

.previewFileList {
  margin-top: 8px;
}
