.header {
  margin: 20px 0 10px 0;
  font: bold;
}

.content {
  width: 100%;
  margin: 20px 0;
}

.content textarea {
  width: 100%;
}

.dropdown {
  width: 100%;
}

.dialog {
  min-width: 640px;
}

@media screen and (max-width: 425px) {
  .dialog {
    min-width: auto;
    width: 100%;
  }
}
