.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.dropDown {
  margin: 0 4px;
  font-weight: normal;
}

.dropDown :global(.ui-button__content) {
  font-weight: normal;
  font-size: 12px;
}

.dropDown :global(.ui-icon) > svg {
  width: 12px;
  height: 12px;
}

.dropDown :global(.ui-dropdown__container) {
  width: 80px;
}
