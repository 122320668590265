.statusText {
  vertical-align: middle;
}
.statusHelpButton {
  vertical-align: middle;
}

.statusLabel {
  vertical-align: middle;
}

.tooltipContent {
  /* Tooltip.contentのpadding: 5 12 7 12 に足して12に均す */
  padding: 7px 0 5px 0;
}
