.popUpContentGrid {
  gap: 20px;
  max-width: 31.6rem;
}

.linkURLInput {
  text-overflow: 'ellipsis';
}

.buttonGrid {
  gap: 10px;
  grid-template-areas: 'box1 box2 box3 box4 box5';
}

.buttonGrid button {
  width: max-content;
}
