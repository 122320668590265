.boxStyle {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.messageAttachmentBoxStyleTwo {
  flex: 1 0 49%;
  max-width: 49%;
  margin: 1px;
}

.messageAttachmentBoxStyleOne {
  flex: 1 0 98%;
  max-width: 98%;
  margin: 1px;
}

.messageAttachmentBoxStyleThree {
  flex: 1 0 33%;
  max-width: 33%;
  margin: 1px;
}

.messageAttachmentHeader {
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}
