.container {
  background-color: #f5f5f5;
}

.navigatorRow {
  padding: 8px 24px 0;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 16px;
}

.paginatorRow {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
  margin-top: 16px;
}
