.container {
  background-color: #f5f5f5;
  height: 100vh;
}

.body {
  display: flex;
}

.bodyLeft {
  flex: 1;
  overflow-y: scroll;
}
