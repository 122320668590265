.content {
  width: 116px;
}

.resetButton {
  width: 100%;
  padding: 0 4px;
}

.resetButton :global(.ui-button__content) {
  font-size: 12px;
  font-weight: normal;
}

.colorPalette {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
}

.colorPaletteItem {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px rgba(37, 36, 36, 0.2);
  cursor: pointer;
}
