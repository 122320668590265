.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f4f4f4;
  position: relative;
}

.mainContent {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.referenceDocumentsPanel {
  position: relative;
  z-index: 2;
}

.ticketList {
  padding: 0px 32px;
  padding-right: 0px;
}

.newTicket {
}
