.container {
  background-color: #fff;
  border-radius: 4px;
}

.inner {
  padding: 24px;
}

.detailTitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listTable {
  margin-top: 16px;
  table-layout: fixed;
  width: 100%;
}

.listTable tr td:nth-child(1) {
  width: 50%;
  color: #8a8a8a;
}

.listTable tr:not(:first-child) td {
  padding-top: 12px;
}

.listTable tr td:nth-child(2) {
  width: 50%;
}

.bottom {
  padding: 12px 24px;
  border-top: solid 1px #ebebeb;
}

.bottomButtonGroup {
  display: flex;
  justify-content: space-between;
}

.outter {
  padding-top: 1rem;
}

.memberCheckText {
  color: #5b5fc7;
  margin-left: 8px;
  cursor: pointer;
  text-decoration: underline;
}

.notificationDestinationText {
  vertical-align: top;
  padding-top: '1.2rem';
}
