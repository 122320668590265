.columnOptionIcon {
  height: 16px !important;
  width: 16px !important;
  min-width: 0 !important;
}

.sortIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  display: flex;
  align-items: center;
}
