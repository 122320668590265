html {
  height: 100%;
}

body {
  height: 100%;
}

.App {
  background-color: #fff;
  height: 100%;
}
