.container {
  display: flex;
  background-color: transparent;
}

.iconBox {
  padding-top: 8px;
}

.messageBox {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 8px;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  word-break: break-all;
}
.messageBox:hover {
  background-color: #fcfcfc;
}

.messageTextBox {
  padding: 8px 16px;
}

.messageReplyBox {
  background-color: #fafafa;
  border-top: solid 1px #ebebeb;
}

.messageReplyControl {
  padding: 4px 16px;
  cursor: pointer;
  color: #5b5fc7;
}

.messageReplyButton {
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 28px;
  color: #424242;
}
.messageReplyButton:hover {
  cursor: pointer;
  color: #5b5fc7;
}

.messageReplyBoxText {
  margin-left: 8px;
}

.messageReplyList {
}

.infoTime {
  margin-left: 8px;
  color: #424242;
}

.replyChatInput {
  padding-bottom: 8px;
}

.fileList {
  margin-top: 8px;
}
